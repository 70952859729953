body {
  background-color: #f6f6f6;
  height: 100%;
}

html {
  height: 100%;
}

.sidebar-child > header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
}

.sidebar-child > header .navbar-brand {
  padding-left: 1.5rem;
  font-size: 1.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sidebar-child > .list-group .list-group-item {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  font-size: 0.8125rem;
  font-weight: 500;
}

.bmd-layout-drawer {
  box-shadow: 3px 0px 14px 0px rgba(0, 0, 0, 0.16) !important;
}

.modal-backdrop {
  z-index: 1040 !important;
}
/* Fullscreen modal */
.modal-full .modal-dialog {
  min-width: 100%;
  margin: 0;
  z-index: 1060;
}

.modal-content {
  z-index: 1100 !important;
}

.modal-full .modal-dialog .modal-content {
  min-height: 100vh;
  z-index: 1060;
}

/* 90% width modal */
.modal-90 .modal-dialog {
  min-width: 90% !important;
  z-index: 1060;
}

.logo {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 40px;
}

.header {
  height: 56px;
}

.not-logged-in {
  padding: 0 4px;
}

.top-section {
  position: relative;
}

.menu-selection {
  position: absolute;
  top: 140px;
  left: 0;
  right: 0;
  border: solid 1px #7c1d21;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0 0 5px 5px;
  padding: 10px;
}

.user-menu {
  padding-right: 70px;
}

@media (min-width: 1200px) {
  .workflow-list .col-lg-1 {
    width: 150px;
  }
}

/*
  Print Template Editor
*/
.dropped-field {
  opacity: 0.5;
  font-weight: bold;
  position: absolute;
  min-width: 100px;
}

.resize-drag {
  padding-top: 5px;
}

.canvas-container {
  position: absolute !important;
}

.draggable {
  cursor: move;
}

/* Set CKEditor z-index so that it is above Bootstrap modals */
:root {
  --ck-z-default: 1070 !important;
  --ck-z-modal: calc(var(--ck-z-default) + 999) !important;
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

/*
 * mxGraph styles
 */
div.mxPopupMenu {
  position: absolute;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.125rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

table.mxPopupMenu {
  width: 100%;
}

tr.mxPopupMenuItem {
  cursor: pointer;

  min-width: 7rem;
  max-width: 17.5rem;
  min-height: 3rem;
  padding: 0.8rem 1rem 0.8rem 1rem;
  line-height: 1;
  text-overflow: ellipsis;
  word-wrap: break-word;

  width: 100%;
  clear: both;
  font-weight: 400;
  color: #212529;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

tr.mxPopupMenuItemHover {
  background-color: #f3f3f3;
}

td.mxPopupMenuItem {
  cursor: pointer;
  min-width: 7rem;
  max-width: 17.5rem;
  min-height: 3rem;
  padding: 0.8rem 1rem 0.8rem 1rem;
  line-height: 1;
  text-overflow: ellipsis;
  word-wrap: break-word;

  clear: both;
  font-weight: 400;
  color: #212529;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

td.mxPopupMenuIcon {
  display: none;
}

div.mxWindow {
  -webkit-box-shadow: 3px 3px 12px #c0c0c0;
  -moz-box-shadow: 3px 3px 12px #c0c0c0;
  box-shadow: 3px 3px 12px #c0c0c0;
  border: 1px solid #c3c3c3;
  position: absolute;
  overflow: hidden;
  z-index: 1;
}

.mxTooltip {
  position: absolute;

  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  background-color: rgba(97, 97, 97, 0.9);
  border-radius: 0.125rem;

  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: 0.875rem;
}

/**
* workflow activity cells
*/
.card-activity .card-body {
  text-align: center;

  padding-right: 1rem;
  padding-left: 1rem;

  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.card-activity.card h1.card-title,
.card-activity.card h2.card-title,
.card-activity.card h3.card-title,
.card-activity.card h4.card-title,
.card-activity.card h5.card-title,
.card-activity.card h6.card-title {
  font-size: 1rem;
}
.card-activity.card {
  font-size: 0.75rem;
  font-weight: 300;
}
.card-activity .card-title {
  margin-bottom: 0.15rem;
}
.card-activity.card-selected {
  filter: brightness(85%);
}
.card-activity {
  color: #00395e;
}
.card-activity.card.activity-invalid {
  border: 4px solid #d50000;
  padding: 2px;
}
.card-activity.card.activity-warning {
  border: 4px solid #da861a;
  padding: 2px;
}
.card-activity.card.activity-completed {
  border: 4px solid green;
}
.card-activity.card.activity-inprogress {
  border: 4px solid blue;
}
.card-activity.card.activity-canceled {
  border: 4px solid pink;
}

/**
* Card variations
*/
.card-sm .card-body {
  padding: 0.75rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.card-sm .card-title {
  margin-bottom: 0;
  font-size: 16px;
}

.card-grey {
  background-color: #f6f6f6;
}

.card-grey label {
  margin-bottom: 0;
}

/**
* Role colors
*/
.rc-red {
  background-color: #6b0b0b !important;
}
.rc-green {
  background-color: #056b5d !important;
}
.rc-yellow {
  background-color: #abaa09 !important;
}
.rc-orange {
  background-color: #ab6500 !important;
}
.rc-purple {
  background-color: #695eb0 !important;
}
.rc-light-blue {
  background-color: #4d748e !important;
}
.rc-light-green {
  background-color: #50978e !important;
}
.rc-light-yellow {
  background-color: #dddd9d !important;
  color: #000 !important;
}

.rc-border-red {
  border: 2px solid #6b0b0b !important;
}
.rc-border-green {
  border: 2px solid #056b5d !important;
}
.rc-border-yellow {
  border: 2px solid #abaa09 !important;
}
.rc-border-orange {
  border: 2px solid #ab6500 !important;
}
.rc-border-purple {
  border: 2px solid #695eb0 !important;
}
.rc-border-light-blue {
  border: 2px solid #4d748e !important;
}
.rc-border-light-green {
  border: 2px solid #50978e !important;
}
.rc-border-light-yellow {
  border: 2px solid #dddd9d !important;
}

.validation .collapse .card-body {
  padding: 0;
}
.validation .btn {
  text-transform: none;
}
.accordion .card {
  overflow: inherit;
}

@media print {
  .no-print {
    display: none !important;
  }
  .no-print.bmd-layout-drawer {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
    width: 0 !important;
  }
  .no-print-margin.bmd-layout-content {
    margin: 0 !important;
  }
}

.data-entity-node {
  color: red;
  cursor: pointer;
}

#editor {
  position: relative;
}

.footnote-tooltip {
  cursor: auto;
  position: absolute;
  /* left: -30px; */
  /* top: calc(100%); */
  top: 40px;
  background: silver;
  padding: 3px;
  border-radius: 2px;
  width: 200px;
  color: blue;
  z-index: 2000;
}
.footnote-tooltip::before {
  border: 5px solid silver;
  border-top-width: 0px;
  border-left-color: transparent;
  border-right-color: transparent;
  position: absolute;
  top: -5px;
  left: 27px;
  content: ' ';
  height: 0;
  width: 0;
}

.suggestion-item-list {
  background: silver;
  color: blue;
  z-index: 20000;
}

.ngx-datatable.material {
  box-shadow: none;
}

.ngx-datatable.material datatable-body datatable-progress {
  position: absolute !important;
  width: 100%;
}

.text-red {
  color: red !important;
}

.show .dropdown-toggle.btn-primary {
  background-color: #00395e;
}

.datatable-checkbox input[type='checkbox']:before {
  border: 2px solid rgba(0, 0, 0, 0.54);
}
.small-card-header {
  padding: 0px !important;
  border: none;
  box-shadow: none !important;
}

.small-card-header .accordion .card {
  box-shadow: none !important;
  padding: 0px;
}
.small-card-header .accordion .card .card-body {
  box-shadow: none !important;
  padding: 0px;
}

.small-card-header .accordion .card .card-header {
  padding: 0px !important;
  border: none;
  box-shadow: none !important;
  margin-bottom: 0px;
}

.deletePermissible {
  line-height: 0px;
  margin-bottom: 0px;
}

.actions {
  padding-top: 15px;
}

.visually-hidden {
  display: none;
}

ngb-timepicker .bmd-form-group {
  padding-top: 0;
}
